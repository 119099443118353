.navbar {
  --bs-navbar-color: #111;
}

.headerPhone {
  font-size: 1.5rem;
}

.boxShadow {
  box-shadow: 0 0.3rem 0.8rem rgba(0, 0, 0, 0.4) !important;
}

.bg-light {
  background-color: #fff !important;
}

.logo {
  max-height: 60px;
}

.fullScreen {
  object-fit: cover;
  object-position: center;
}

.opacity-img {
  opacity: 0.85 !important;
}

.card img {
  filter: brightness(100%);
  transition: filter 0.2s ease-in-out;
}

.card:hover img {
  filter: brightness(70%);
}

.shadows {
  text-shadow: 2px 2px 4px #222;
}

.bg-darkBlue {
  background-color: #002b48;
}

.bg-darkGreen {
  background-color: #00480f;
}

.translate-middle-card {
  transform: translate(-50%, -50%) !important;
}

.start-50-card {
  left: 50% !important;
}

.footer {
  /* background-color: #242d33; */
  color: white;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#45484d+0,000000+100;Black+3D+%231 */
  background: #45484d; /* Old browsers */
  background: -moz-linear-gradient(
    top,
    #45484d 0%,
    #000000 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    #45484d 0%,
    #000000 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    #45484d 0%,
    #000000 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#45484d', endColorstr='#000000',GradientType=0 ); /* IE6-9 */
}

.quoteFormHeader {
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #242d33;
  margin-bottom: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.quoteForm {
  background: rgba(1, 1, 1, 0.2);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.quoteFormButton {
  width: 33%;
  margin-bottom: 20px;
}

.formInput,
.errorFormInput {
  width: 100%;
  margin-bottom: 20px;
  border: 1px solid #999;
  background-color: white;
  border-radius: 5px;
}

.errorFormInput {
  border: 2px solid red;
}

.waitMessage {
  font-size: 1.1rem;
  color: rgb(230, 0, 0);
  margin-bottom: 10px;
}

.floatImgWidth {
  width: 15%;
}

.image-grow {
  animation-name: grow-fade;
  animation-duration: 3s;
  animation-timing-function: linear;
  animation-delay: 1s;
  animation-fill-mode: forwards;
}

.success-message {
  width: 100%;
  z-index: 10000;
  opacity: 1;
  visibility: visible;
  left: -1px;
}

@keyframes grow-fade {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  70% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: scale(5);
    visibility: hidden;
  }
}

.fadeIn {
  opacity: 1;
  transition: opacity 1s ease;
}

.fadeOut {
  opacity: 0;
  transition: opacity 1s ease;
}

.fadeOutMessage {
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s ease;
}

@media (min-width: 992px) {
  .navContainer {
    width: auto;
  }
  .headerPhoneButton {
    display: none;
  }
}
